<template>
    <v-container>

      <v-card elevation="2" class="pa-5">

            <v-card-title class="pb-0 primary--text">Maintenance Record</v-card-title>

            <v-form class="main-form-card">
              <v-card class="mt-8 pa-5">
                <v-container>
                  <v-row>

                    <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    >
                      <v-select
                      v-on:change="selectValueStation"
                      :items="itemStation"
                      label="Station"
                      prepend-icon="mdi-store-24-hour"
                      required
                      outlined 
                      dense
                      ></v-select>
                    </v-col>

                    <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    >
                      <v-select
                      prepend-icon="mdi-calendar"
                      v-on:change="selectValueYearDate"
                      :items="dropdownYearDate"
                      label="Year"
                      class="input-date-year"
                      outlined 
                      dense
                      >
                      </v-select>
                      <!-- <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        v-model="modalStartCalendar"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="monthDate"
                            label="Month"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined 
                            dense
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                          v-on:change="selectMonthDate"
                          v-model="monthDate"
                          type="month"
                          @input="modalStartCalendar = false"
                          ></v-date-picker>
                        </v-menu> -->
                    </v-col>

                    <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    >
                      <v-select
                      prepend-icon="mdi-calendar"
                      v-on:change="selectValueMonthDate"
                      :items="dropdownMonthDate"
                      label="Month"
                      class="input-date-year"
                      outlined 
                      dense
                      >
                      </v-select>
                    </v-col>

                    <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    >
                      <v-btn :disabled="disableBtnGenerate" class="primary" @click="generateTable">
                        Generate
                      </v-btn>
                    </v-col>

                  </v-row>

                    <v-data-table
                    :headers="theadSummaryMaintenance"
                    :items="tbodySummaryMaintenance"
                    :header-props="{ sortIcon: null }"
                    class="elevation-1 my-5 headerDtSarawak"
                    :items-per-page="10"
                    no-select-on-click
                    fixed-header
                    :loading="loadingTable"
                    loading-text="Data is loading.."
                    >

                      <template v-slot:[`item.datetime`]="{ item }">
                          <span v-html="item.datetime"></span>
                        </template>

                        <template v-slot:[`item.fromDatetime`]="{ item }">
                          <span v-html="item.fromDatetime"></span>
                        </template>

                      <template v-slot:[`item.checkbox`]="{ item }">
                          <v-checkbox
                          class="checkbox-dt"
                          :value="item.checkbox"
                          v-model="item.checkbox"
                          v-ripple
                          @change="check(item,$event)"
                          ></v-checkbox>
                      </template>

                    </v-data-table>

                  <v-btn
                  class="primary mr-5"
                  @click="allCheck"
                  >{{textSelect}} All
                  </v-btn>

                  <v-btn
                  class="primary"
                  @click="unflag"
                  >Unflag
                  </v-btn>


                </v-container>
              </v-card>
            </v-form>

      </v-card>


      <!-- Popups/Dialogs Success Unflag Maintenance Record-->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessUnflag"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 success white--text">
                Done! <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Maintenance is done.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:#106f79 !important;"
                    color="success"
                    text
                    @click="dialogSuccessUnflag = false;reloadPage();"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error Unflag Maintenance Record-->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorUnflag"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Something when wrong. Please try again.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:red !important;"
                    color="error"
                    text
                    @click="dialogErrorUnflag = false;reloadPage();"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
        
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({

    itemStation: [],
    stationSelected: null,
    // monthDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
    yearDate: null,
    monthDate: null,
    dropdownYearDate: [],
    dropdownMonthDate: [
      "All Month",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    listMonthDate: [
      { id:"all", text:"All Month" },
      { id:"01", text:"January" },
      { id:"02", text:"February" },
      { id:"03", text:"March" },
      { id:"04", text:"April" },
      { id:"05", text:"May" },
      { id:"06", text:"June" },
      { id:"07", text:"July" },
      { id:"08", text:"August" },
      { id:"09", text:"September" },
      { id:"10", text:"October" },
      { id:"11", text:"November" },
      { id:"12", text:"December" }
    ],
    disableBtnGenerate: true,

    dataOnGoing: [],

    // Engine
    // modalStartCalendar: false,
    // modalEndCalendar: false,

    theadSummaryMaintenance: [
      {text: "Station ID", value: "stationId", sortable: true},
      {text: "Date & Time", value: "datetime", sortable: true},
      {text: "Parameter", value: "parameter", sortable: true},
      {text: "Start Date & Time", value: "fromDatetime", sortable: true},
      {text: "Create By", value: "flagBy", sortable: true},
      {text: "Action", value: "checkbox", sortable: true},

    ],
    tbodySummaryMaintenance: [],

    valueCheckbox: false,
    checkboxSelected: [],
    selectAll: false,
    textSelect: "Select",
    disableBtn: true,

    dialogSuccessUnflag: false,
    dialogErrorUnflag: false,
    loadingTable: false,

  }),
  methods:{

    load(){
      this.loadStationList();
      this.getApiDateYear();
      this.loadDataMaintenanceRecord("All Stations",'all',this.thisYear());
    },

    thisYear(){
      const d = new Date();
      let year = d.getFullYear();
      return year;
    },

    getApiDateYear(){
          axios.get('https://temptunnel1.shaz.my/api/cms/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // this.stationDetails = response.data;
            // console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
              this.dropdownYearDate.push(response.data[i]);
            }

            
            // console.log(this.itemStation);

        })
        .catch(error => {
            console.log(error);
        })
    },

    selectValueYearDate: function(e){
      this.yearDate = e;

      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    selectValueMonthDate: function(e){

      for(let i in this.listMonthDate){
        if (this.listMonthDate[i].text == e) {
          this.monthDate = this.listMonthDate[i].id;
        }
      }


      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    loadStationList(){
      
      this.itemStation = [];

      axios.get('https://temptunnel1.shaz.my/api/cms/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // console.log(response.data);

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
          }

          this.itemStation.unshift("All Stations");
          
      })
      .catch(error => {
          console.log(error);
      })

    },

    loadDataMaintenanceRecord(station,month,year){

      // console.log('https://temptunnel1.shaz.my/api/maint/maintcal2?month='+month+'&year='+year);

      axios.get('https://temptunnel1.shaz.my/api/maint/maintcal2?month='+month+'&year='+year, {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // console.log(response.data);

          let data = response.data;
          this.tbodySummaryMaintenance = [];
          this.dataOnGoing = [];

          console.log(data);

          for(let i in data){
            if (data[i]["datetime"] != null) {
              // res["datetime"] = res["datetime"].replaceAll("T", "<br>");
              data[i]["datetime"] = this.convertDateFormat(data[i]["datetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["datetime"].split("T")[1]);
            }
            if (data[i]["fromDatetime"] != null) {
              // data[i]["fromDatetime"] = data[i]["fromDatetime"].replaceAll("T", "<br>");
              data[i]["fromDatetime"] = this.convertDateFormat(data[i]["fromDatetime"].split("T")[0]) + "<br>" + this.convert12Hours(data[i]["fromDatetime"].split("T")[1]);
            }
          }

          for(let i in data){
            if (data[i].toDate != null || data[i].toDate != "") {
              if (data[i].flag === "M" && data[i].unFlagBy == null) {
                if(station == "All Stations"){
                  this.dataOnGoing.push(data[i]);
                }
                else{
                  if (data[i].stationId === station) {
                    this.dataOnGoing.push(data[i]);
                  }
                }
              }
            }
          }
          console.log(this.dataOnGoing);
          this.tbodySummaryMaintenance = this.dataOnGoing;

          for(let i in this.tbodySummaryMaintenance){
            this.tbodySummaryMaintenance[i]["checkbox"] = false;
          // console.log(this.tbodySummaryMaintenance[i]);
          }

          this.loadingTable = false;

          // console.log(this.tbodySummaryMaintenance);
          
      })
      .catch(error => {
          console.log(error);
          this.loadingTable = false;
      })

    },

    selectValueStation: function(e){
      this.stationSelected = e.split(" - ")[0];

      if (this.stationSelected != null && this.yearDate != null && this.monthDate != null) {
        this.disableBtnGenerate = false;
      }
    },

    // selectMonthDate: function(e){

    // },

    // selectEndDate: function(e){

    // },

    generateTable(){
      // console.log(this.stationSelected);
      if (this.stationSelected != null) {
        this.loadingTable = true;
        this.disableBtn = false;
        this.loadDataMaintenanceRecord(this.stationSelected,this.monthDate,this.yearDate);
      }
    },

    check(item,event){ 
      console.log(item, event);
      // console.log(item.checkbox);

      // console.log(item.checkbox);
      // if (item.checkbox === null) {
      //   item.checkbox = false;
      // }

      if (item.checkbox === true) {
          this.checkboxSelected.push(item);
          if (this.checkboxSelected.length == this.dataOnGoing.length) {
            this.textSelect = "Deselect";
            this.selectAll = !this.selectAll
            this.valueCheckbox = true;
          }
      }
      else if (item.checkbox === false || item.checkbox == null){
          for (let i = 0; i < this.checkboxSelected.length; i++) {
              if (this.checkboxSelected[i].stationId == item.stationId
              && this.checkboxSelected[i].parameter == item.parameter
              && this.checkboxSelected[i].fromDatetime == item.fromDatetime) {
                  this.checkboxSelected.splice(i,1);
              }
          }
          if (this.checkboxSelected.length == 0) {
            this.textSelect = "Select";
            this.selectAll = !this.selectAll
            this.valueCheckbox = false;
          }
      }

      // // this.checkboxSelected.push(item);
      console.log(this.checkboxSelected);

    },

    allCheck(){

      this.checkboxSelected = [];

      this.selectAll = !this.selectAll

      if (this.selectAll == true) {
        this.textSelect = "Deselect";
        for(let i in this.tbodySummaryMaintenance){
          this.tbodySummaryMaintenance[i]["checkbox"] = true;
          this.checkboxSelected.push(this.tbodySummaryMaintenance[i]);
        }
        console.log(this.checkboxSelected);
        this.valueCheckbox = true;
      }
      else{
        this.textSelect = "Select";
        for(let i in this.tbodySummaryMaintenance){
          this.tbodySummaryMaintenance[i]["checkbox"] = false;
        }
        this.checkboxSelected = [];
        this.valueCheckbox = false;
      }

      console.log(this.checkboxSelected);
    },

    unflag(){

      if (this.checkboxSelected.length > 0) {

        for(let i in this.checkboxSelected){
          if (this.checkboxSelected[i]["datetime"] != null) {
            this.checkboxSelected[i]["datetime"] = this.convertToDbDt(this.checkboxSelected[i]["datetime"].split("<br>")[0]) + "T" + this.convert24Hours(this.checkboxSelected[i]["datetime"].split("<br>")[1]);
          }
          if (this.checkboxSelected[i]["fromDatetime"] != null) {
            this.checkboxSelected[i]["fromDatetime"] = this.convertToDbDt(this.checkboxSelected[i]["fromDatetime"].split("<br>")[0]) + "T" + this.convert24Hours(this.checkboxSelected[i]["fromDatetime"].split("<br>")[1]);
          }
        }

        let objJson = JSON.stringify(this.checkboxSelected);
        console.log(objJson);

        axios.put('https://temptunnel1.shaz.my/api/maint/endmaintcal', objJson, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response.data);
            this.dialogSuccessUnflag = true;
        })
        .catch(error => {
            console.log(error);
            this.dialogSuccessUnflag = false;
            this.dialogErrorUnflag = true;
        })
      }

    }
      
  },
  mounted(){
    this.load();
    
  }

}
</script>

<style lang="scss">

@import '~scss/main';

.v-subheader {
  height: 30px;
}

.col-dateYear{
  display: flex !important;
}
</style>